<template>
  <div class="content">


    <div class="accountCon">
      <el-button type="primary" class="mb10" size="mini"  @click="dialogVisible = true">添加模板</el-button>
      <!--列表-->
      <el-row>
        <el-col :span="24">
          <el-table
            :data="list"
            size="small"
            border
            :cell-style="$style.cellStyle"
            :header-cell-style="$style.rowClass"
            @selection-change="handleSelectionChange"
          >
          <ElTableColumn label="模板名称" prop="name" width="100"/>
          <ElTableColumn label="内容"  prop="content" />
            <ElTableColumn label="审核状态" width="100">
              <template slot-scope="{ row }">
                <div v-if="row.status == 0">审核中</div>
                <div v-if="row.status == 1">通过</div>
                <div v-if="row.status == 2">未通过</div>
              </template>
            </ElTableColumn>
           <ElTableColumn label="操作"  fixed="right" width="100">
              <template
                slot-scope="{ row }"
              >
                <span class="peril" @click="tapBtn('delete',row.id)"> 作废</span>
              </template>
            </ElTableColumn>
          </el-table>
<!--          <pagination
            class="page tc mt10"
            :total="page.total"
            :page.sync="page.page"
            :limit.sync="page.limit"
            @pagination="getList"
          /> -->
          <el-dialog
            title="添加模板"
            :visible.sync="dialogVisible"
            width="30%"
            >
            <span>内容</span>
                <el-input v-model="content" type="textarea"></el-input>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary"  @click="tapBtn('add')">确 定</el-button>
            </span>
          </el-dialog>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import pagination from '@/components/Pagination'
import { getshortmessagetpllist,tplCancel,addModel } from "@/api/permission";
class Page {
  total = 0;
  page = 1;
  limit = 10;
}
class Search {
  name = ''; // 名称
  status = ''; // 状态
}
class Options {
  constructor () {
    this.status = [
      { id: 1, name: '销售中' },
      { id: 2, name: '已下架' }
    ]
  }
}
export default {
  name: 'PointGoodsList',
  components: {
    pagination
  },
  data () {
    return {
      search: new Search(), // 检索条件
      options: new Options(), // 选项
      page: new Page(), // 分页
      content:"",
      dialogVisible:false,
      list: [], // 数据展示
    }
  },
computed: {
    ...mapGetters(['userInfo']),
  },
  mounted () {
    this.getList()
  },
  methods: {
    // 获取列表
    getList (option) {
      if (option === 'search') this.page = new Page();
      getshortmessagetpllist({...this.search,...this.page}).then(res => {
        this.list = res.data;
        this.page.total = res.total;
      })
    },
    // 点击按钮
    tapBtn (type,id) {
       switch (type) {
        case 'delete':
          tplCancel({id:id}).then(res => {
            if(res.code==200)
            {
              this.$message({
                type: 'success',
                message: "已作废"
              });
              this.getList();
            }else{
                this.$message.error('作废失败');
            }
          });
          break
        case 'add':
          let content=this.content
          addModel({content:content}).then(res=>{
            if(res.code==200)
            {
              this.$message({
                type: 'success',
                message: "添加成功"
              });
              this.dialogVisible=false;
              this.getList();
            }else{
                this.$message.error('添加失败');
            }
          });
          break
      }
    },
    handleSelectionChange (val) {
      this.idList = val.map(i => {
        return i.id
      })
    }

  }
}
</script>

<style lang="scss" scoped>

.bgW {
  background: #fff;
  border-radius: 3px;
  margin-bottom: 20px;
}
.el-form-item {
  margin: 5px  40px  3px 0 ;
}
.accountCon{
    background: #fff;
    padding: 10px 0px 80px;
}
</style>
