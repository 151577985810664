import axios from '@/utils/axios'

// ----------------------------角色管理-------------------------------

// 角色列表
export const roleList = params => {
  return axios({
    url: 'admin/group/index',
    method: 'get',
    params
  }).then(res => {
    return res.data
  })
}

// 权限表
export const menuList = params => {
  return axios({
    url: '/admin/menu/index',
    method: 'get',
    params
  }).then(res => {
    return res.data
  })
}

// 新增角色
export const addRole = data => {
  return axios({
    url: '/admin/group/save',
    method: 'post',
    data
  })
}

// 删除角色
export const deleteRole = data => {
  return axios({
    url: '/admin/group/delete',
    method: 'post',
    data
  })
}

// 新建角色部门列表
export const departmentList = data => {
  return axios({
    url: '/admin/market/readAdminMarketWithList',
    method: 'post',
    data
  })
}

// 编辑角色
export const updateRole = data => {
  return axios({
    url: '/admin/group/update',
    method: 'post',
    data
  })
}

// 角色详情
export const roleDetail = params => {
  return axios({
    url: '/admin/group/read',
    method: 'get',
    params
  }).then(res => {
    return res.data
  })
}


// ----------------------------部门管理-------------------------------

// 部门列表
export const readDepartmentList = data => {
  return axios({
    url: '/admin/department/readDepartmentList',
    method: 'post',
    data
  })
}

// 添加部门
export const createDepartmentResource = data => {
  return axios({
    url: '/admin/department/createDepartmentResource',
    method: 'post',
    data
  })
}

// 编辑部门
export const updateDepartmentResource = data => {
  return axios({
    url: '/admin/department/updateDepartmentResource',
    method: 'post',
    data
  })
}


// ----------------------------管理员-------------------------------

// 管理员列表
export const readAdminUserList = data => {
  return axios({
    url: '/admin/AdminUser/readAdminUserList',
    method: 'post',
    data
  })
}

// 添加管理员
export const createAdminUserResource = data => {
  return axios({
    url: '/admin/AdminUser/createAdminUserResource',
    method: 'post',
    data
  })
}

//编辑管理员
export const updateAdminUserResource = data => {
  return axios({
    url: '/admin/AdminUser/updateAdminUserResource',
    method: 'post',
    data
  })
}

//禁用管理员
export const updateStatus = data => {
  return axios({
    url: '/admin/AdminUser/updateAdminUserStatus',
    method: 'post',
    data
  })
}

// ----------------------------标签-------------------------------

//标签列表
export const read_member_label = data => {
  return axios({
    url: '/crm/customer/read_member_label',
    method: 'post',
    data
  })
}

//添加标签
export const add_member_label = data => {
  return axios({
    url: '/crm/customer/add_member_label',
    method: 'post',
    data
  })
}

//编辑标签
export const save_member_label = data => {
  return axios({
    url: '/crm/customer/save_member_label',
    method: 'post',
    data
  })
}
// 短信模板列表
export const getshortmessagetpllist = data =>
  axios.post('/crm/customerPushController/gettpllist', data)
    .then(res => res)
// 短信模板删除
export const tplCancel = data =>
  axios.post('/crm/customerPushController/tplCancel', data)
    .then(res => res)
// 短信模板添加
export const addModel = data =>
  axios.post('/crm/customerPushController/addModel', data)
    .then(res => res)
// 客户列表
export const shortMessageMass = params => axios({
  method: 'get',
  url: '/crm/customerPushController/shortMessageMass',
  params
}).then(res => res)
// 短信单发
export const shortMessageSend = data =>
  axios.post('/crm/customerPushController/send', data)
    .then(res => res)
// 短信群发
export const shortMessageSends = data =>
  axios.post('/crm/customerPushController/sends', data)
    .then(res => res)
// 公众号模板列表
export const weChatGzhTpl = data =>
  axios.post('/crm/customerPushController/weChatGzhTpl', data)
    .then(res => res)
